// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import Parallax from 'parallax-js';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

// Import jQuery UI (this automatically includes jQuery UI's core and effects)
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/effects/effect-slide';

// Make sure to include jQuery UI's CSS if you want the default styles
// import 'jquery-ui/themes/base/all.css';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  const onToggleActive = (element) => (e) => {
    e.preventDefault();
    element.classList.toggle('active');
    if (element.classList.contains('active')) {
      document.querySelector('.search__input').focus();
    }
  };

  const searchModalTrigger = document.querySelector('.js-search-button');
  const overlay = document.querySelector('.js-overlay');
  const modalContent = overlay.querySelector('.modal-content');
  const header = document.querySelector('.header');

  searchModalTrigger.addEventListener('click', onToggleActive(overlay));
  overlay.addEventListener('click', onToggleActive(overlay));
  modalContent.addEventListener('click', (e) => e.stopPropagation());

  if (window.innerWidth > 768) {
    const scenes = document.querySelectorAll('.scene');
    scenes.forEach((scene) => {
      new Parallax(scene, {
        limitY: 20,
        limitX: 100,
        pointerEvents: true,
      });
    });

    if ($('.scenic-photo').length) {
      var bgPosition = 0;
      setInterval(function () {
        bgPosition++;
        $('.scenic-photo').attr(
          'style',
          `background-position: ${bgPosition}px 0 !important`
        );
      }, 75);
    }
  }

  $('.partners-accordion').each(function () {
    var partners = $(this).find('.partners-accordion-item'),
      partnersNumber = partners.size(),
      cnt = -1;

    partners.each(function () {
      cnt++;
      $(this)
        .find('.partners-accordion-item-color')
        .css('opacity', cnt * (1 / partnersNumber));
    });
  });

  $('.partners-accordion-item').each(function () {
    $(this).click(function () {
      $(this)
        .find('.partners-accordion-item-content')
        .slideToggle(600, 'easeOutBack');
    });
  });
  if (header) {
    document.documentElement.setAttribute(
      'style',
      `scroll-padding-top: ${header.offsetHeight}px`
    );
  }

  $('.slider-grid .grid-slide').on('click', function () {
    var index = $(this).data('slide-index');

    // Hide all main slides
    $('.main-slider .main-slide').removeClass('active');
    $('.custom-images-slider .grid-slide').removeClass('active');

    // Show the clicked main slide
    $('.main-slider .main-slide[data-slide-index="' + index + '"]').addClass(
      'active'
    );

    $(this).addClass('active');
  });

  if (window.location.hash) {
    // news+more
    const $target = $(window.location.hash);
    if ($target.length) {
      setTimeout(() => {
        $('html, body').animate(
          {
            scrollTop: $target.offset().top - 110,
          },
          300
        );
      }, 600);
    }
  }

  $('a').on('click', function (e) {
    const href = $(this).attr('href');
    if (href.includes('#')) {
      e.preventDefault;

      const target = href.split('#')[1];
      const $target = $(`#${target}`);

      if ($target.length) {
        $('html, body').animate(
          {
            scrollTop: $target.offset().top - 110,
          },
          300
        );
      }
    }
  });
  // $('.scene').parallax();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
